import * as React from "react";

import release1 from "../img/release1.png";
import release2 from "../img/release2.png";


const MoodMixes = class extends React.Component {
  render() {
    return (
      <>

        <div id="moodMixes">

            <div className="title">Mood Mixes</div>
            <div className="introText">
                <div>Here is some text about the mood mixes</div>
            </div>
            <div className="mixContent">
                <div className="mix">
                    <img src={release1} />
                    <p>Compuphonic</p>
                    <p>Aquamarine/Fairfax</p>
                </div>
                <div className="mix">
                    <img src={release2} />
                    <p>Yotto</p>
                    <p>Night Walk</p>
                </div>
                <div className="mix">
                    <img src={release1} />
                    <p>Compuphonic</p>
                    <p>Aquamarine/Fairfax</p>
                </div>
                <div className="mix loadmore">
                    <p>More mixes</p>
                </div>
            </div>
        </div>

      </>
    );
  }
};

export default MoodMixes;
