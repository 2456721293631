import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import ScrollAnimation from 'react-animate-on-scroll';

import Layout from "../components/Layout";
import Logo from "../components/Logo";
import Artwork from "../components/Artwork";
import MoodMixes from "../components/MoodMixes";

import MixRoll from "../components/MixRoll";


// eslint-disable-next-line
export const MixesPageTemplate = ({
  title
}) => {

  return (
    <>
      {/* <div className="innerhero">
        <Logo />
        <h1>{title}</h1>
      </div>

      <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay="1500">
        <MoodMixes />
         <MixRoll />
      </ScrollAnimation> */}


      <div className="fourOhFour">
        <h1>NOT FOUND</h1>
        <Link to="/">
          HOME
        </Link>
      </div>

    </>
  );
};

MixesPageTemplate.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
};

const MixesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <>
    <Layout>
      <MixesPageTemplate
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        playlistTitle={frontmatter.playlistTitle}
        playlistSubTitle={frontmatter.playlistSubTitle}
        playlistDescription={frontmatter.playlistDescription}
      />
    </Layout>
    </>
  );
};

MixesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default MixesPage;

export const pageQuery = graphql`
  query MixesPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "mixes-page" } }) {
      frontmatter {
        title

      }
    }
  }
`;
